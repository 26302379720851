import Plaatjie from "@ubo/plaatjie"
import React from "react"

export interface GalleryProps {
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Gallery
}


const Gallery: React.FC<GalleryProps> = ({ fields }) => (
  <section>
    <div className="container py-2 py-lg-5">
      <div className="row">
        {fields?.images?.map((image, index) => (
          <div key={index} className="col-12 col-md-6 mb-4 mb-md-0">
            <Plaatjie image={image} alt={image?.altText || ''} />
          </div>
        ))}
      </div>
    </div>
  </section>
)

export default Gallery
